import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngCryptoExchangeDevelopment = loadable(() => import("../components/CryptoExchangeDevelopment/english.js"));
const ArabicCryptoExchangeDevelopment = loadable(() => import("../components/CryptoExchangeDevelopment/arabic.js"));
const ChineseCryptoExchangeDevelopment = loadable(() => import("../components/CryptoExchangeDevelopment/chinese.js"));


const schema = {
    "@context": "http://schema.org/",
    "@type": "product",
    "image": "https://softtik.com/images/nft-collection/invest-section.webp",
    "name": "Top Cryptocurrency Wallet Development Company",
    "description": "Softtik is a top cryptocurrency wallet development company with over eight years of experience providing high-end development solutions to clients.",
    "brand": "Softtik Technologies",
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "146"
    }
}

export class CryptoExchangeDevelopment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English"
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');
        if (region == 'ae') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/cryptocurrency-exchange-development-services/"
                        title="Prime Cryptocurrency Exchange Development Company - Softtik "
                        thumbnail="https://softtik.com/images/nft-collection/invest-section.webp"
                        description="Softtik is a top cryptocurrency exchange development company that delivers a highly scalable venue capable of buying, selling, and investing digital assets."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicCryptoExchangeDevelopment />
                                    : lang == "China"
                                        ? <ChineseCryptoExchangeDevelopment />
                                        : <EngCryptoExchangeDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default CryptoExchangeDevelopment;